import styled, { css } from 'styled-components'

interface ButtonGroup {
  $centered?: boolean,
}

export const ButtonGroup = styled.div<ButtonGroup>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -0.3rem;

  > * {
    margin: 0.3rem;
  }

  ${ p => p.$centered && css`
    justify-content: center;
  ` }
`

interface Button {
  $color?: string,
  $textColor?: string,
  $flex?: boolean,
  $rounded?: boolean,
  $outline?: boolean,
  $flat?: boolean,
  $inline?: boolean,
}

const buttonBoxShadow = '0px 2px 4px rgba( 0, 0, 0, 0.25 )'

const Button = styled.div<Button>`
  position: relative;

  ${ p => p.$flex && css`
    display: flex;
    align-items: stretch;
  ` }

  > button,
  > a {
    position: relative;
    display: ${ p => p.$inline ? 'inline-flex' : 'flex' };
    align-items: center;
    padding: 0.6rem ${ p => p.$rounded ? '1.5rem' : '1rem' };
    ${ p => p.theme.typo.generalSansSemiBold }
    font-size: inherit;
    line-height: 1.3;
    border: 0;
    border-radius: ${ p => p.$rounded ? '20rem' : '5px' };
    background: ${ p => p.theme.getColor( p.$color ) };
    color: inherit;
    text-decoration: none;
    box-shadow: ${ p => !p.$flat ? buttonBoxShadow : 'none' };
    transition:
      padding-left .3s ease-out;

    svg {
      &:first-child {
        margin-right: 0.6rem;
      }

      &:last-child {
        margin-left: 0.6rem;
        margin-right: -0.5rem;
        margin-top: 3px
      }

      path {
        fill: inherit;
      }
    }

    ${ p => p.$textColor && css`
      color: ${ p.theme.getColor( p.$textColor ) };

      svg path {
        fill: ${ p.theme.getColor( p.$textColor ) };
      }
    ` }

    ${ p => p.$outline && css`
      background: transparent;
      color: ${ p.theme.getColor( p.$textColor || p.$color ) };
      box-shadow:
        inset 0 0 0 1px ${ p.theme.getColor( p.$color ) }
        ${ !p.$flat && `, ${ buttonBoxShadow }` };

      svg path {
        fill: ${ p.theme.getColor( p.$textColor || p.$color ) };
      }
    ` }

    &:focus,
    &:focus-visible {
      outline: none;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: ${ p => p.$rounded ? '20rem' : '5px' };
        box-shadow: 0 0 0 3px ${ p => p.theme.colors.black };
      }
    }

    &:focus:not( :focus-visible ) {
      &:before {
        display: none;
      }
    }

    &:disabled {
      filter: grayscale( 0.8 );
    }
  }
`

Button.defaultProps = {
  $color: 'navy',
  $textColor: 'white',
}

export default Button
