import useKeyDown from './useKeyDown'

const defaultElementsSelector = [
  'a[href]:not([tabindex="-1"])',
  'area[href]:not([tabindex="-1"])',
  'input:not([disabled]):not([tabindex="-1"])',
  'select:not([disabled]):not([tabindex="-1"])',
  'textarea:not([disabled]):not([tabindex="-1"])',
  'button:not([disabled]):not([tabindex="-1"])',
  'iframe:not([tabindex="-1"])',
  '[tabindex]:not([tabindex="-1"])',
  '[contentEditable=true]:not([tabindex="-1"])',
].join( ', ' )

const useFocusTrap = (
  elementRef,
  {
    elementsSelector = defaultElementsSelector,
    additionalElements = [],
    isTrapped = true,
  } = {},
) => {
  useKeyDown( 'Tab', e => {
    const focusableElements = [
      ...(elementRef.current?.querySelectorAll( elementsSelector ) || []),
      ...additionalElements.map( el => el.current ).filter( el => !!el ),
    ]

    const { activeElement } = document
    const activeElementIndex = focusableElements.indexOf( activeElement )
    const first = focusableElements[ 0 ]
    const last = focusableElements[ focusableElements.length - 1 ]

    const focusNext = () => {
      if ( activeElement === last ) {
        first.focus()
      }
      else {
        focusableElements[ activeElementIndex + 1 ].focus()
      }
    }
    
    const focusPrevious = () => {
      if ( activeElement === first ) {
        last.focus()
      }
      else {
        focusableElements[ activeElementIndex - 1 ].focus()
      }
    }

    e.preventDefault()

    if ( e.shiftKey ) {
      focusPrevious()
      return
    }

    focusNext()
  }, [elementsSelector], !isTrapped )
}

export default useFocusTrap
