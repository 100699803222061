import { useState, useEffect, useContext } from 'react'
import { ThemeProvider as TP, ThemeContext } from 'styled-components'
import useRouterLoading from '../hooks/useRouterLoading'
import { typo } from './fonts'
import colors, { getColor } from './colors'

const theme = {
  typo,
  colors,
  getColor,
  noJs: true as boolean,
  isLoading: false as boolean,
  fg: getColor( 'black' ),
  bg: getColor( 'white' ),
} as const

type ThemeType = typeof theme

declare module "styled-components" {
  export interface DefaultTheme extends ThemeType {}
}

export const useTheme = () => useContext( ThemeContext )

function ThemeProvider( { children } ) {
  const [noJs, setNoJs] = useState( true )
  const isLoading = useRouterLoading()

  useEffect( () => {
    setNoJs( false )
  }, [] )

  return (
    <TP theme={ {
      ...theme, noJs, isLoading,
    } as const }
    >
      { children }
    </TP>
  )
}

export default ThemeProvider
