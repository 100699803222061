import styled, { css } from 'styled-components'
import Container from './layout/Container'
import Image from './Image'
import Link from './Link'
import Chevron from './icons/Chevron'
import extractLinkitParts from '../lib/extractLinkitParts'
import textLink from '../styles/textLink'
import { cn } from '../lib/cn'

function Hero( {
  title, 
  intro = null, 
  subtext = null,
  image = null, 
  link = null, 
  linkStyle = 'link' as 'link' | 'button',
} ) {
  const { 
    url: linkUrl, 
    text: linkText, 
    target: linkTarget,
    rel: linkRel,
  } = extractLinkitParts( link )

  const content = (
    <Container>
      <div 
        className={
          cn(
            "font-sans flex flex-col text-pretty pt-36",
            !!image ? 'text-white' : 'text-black',
          )
        }
      >
        <h1 
          className={
            cn(
              "font-general-sans-semi-bold text-tinyHero sm:text-smallHero md:text-hero max-w-3xl", 
              title.length < 85 && 'max-w-2xl',
              title.length < 60 && 'max-w-xl',
              title.length < 35 && 'max-w-lg',
            )
          }
        >
          { title }
        </h1>
        { intro && (
          <div 
            className={
              cn(
                "font-sans text-tinyHero flex flex-col gap-4 sm:text-smallHero md:text-hero text-pretty max-w-3xl mt-1",
                intro.length < 85 && 'max-w-2xl',
                intro.length < 60 && 'max-w-xl',
                intro.length < 35 && 'max-w-lg',
              )
            }
            dangerouslySetInnerHTML={ { __html: intro } } 
          />
        ) }
        { subtext && (
          <p className="text-blue-grey__deep mt-6">{subtext}</p>
        )}
        { linkUrl && linkText && (
          <div>
            <Link 
              className={
                cn(
                  linkStyle === 'link' && 'inline-block mt-8 font-general-sans-semi-bold text-small text-inherit',
                  linkStyle === 'button' && 'inline-flex items-center mt-16 bg-black text-white font-general-sans-semi-bold pl-4',
                )
              }
              href={ linkUrl } 
              target={ linkTarget }
              rel={ linkRel }
            >
              { linkText }
              { linkStyle === 'button' && (
                <span className="ml-4 bg-red px-4 py-3"><Chevron className="h-[22px]" /></span>
              ) }
            </Link>
          </div>
        ) }
      </div>
    </Container>
  )

  const hasButton = linkUrl && linkText && linkStyle === 'button'

  return (
    <div className={ cn('relative',  !hasButton && 'mb-16' ) }>
      { image && (
        <>
          <div className="relative h-[88vh] bg-black">
            <Image
              className="opacity-50"
              src={ image.url }
              alt={ image.altText || '' }
              layout="fill"
              objectFit="cover"
              priority
            />
          </div>
          <div className="absolute inset-0 flex items-center -mt-36">
            { content }
          </div>
        </>
      ) }
      { !image && content }
    </div>
  )
}

const headerPad = '9rem'

interface StyledHeroContent {
  $hasImage: boolean,
  $titleMaxWidth: string,
  $introMaxWidth: string,
}

const StyledHeroContent = styled.div<StyledHeroContent>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: ${ headerPad };
  color: ${ p => p.$hasImage ? p.theme.colors.white : p.theme.colors.black };

  > * {
    width: 100%;
  }

  > div {
    max-width: ${ p => p.$introMaxWidth || '52rem' };
  }

  h1 {
    max-width: ${ p => p.$titleMaxWidth || '52rem' };
    margin: 0;
    ${ p => p.theme.typo.generalSansSemiBold }
    font-size: ${ p => p.theme.typo.sizes.tinyHero };

    span {
      @media ( min-width: 600px ) {
        white-space: nowrap;
      }
    }

    @media ( min-width: 500px ) {
      font-size: ${ p => p.theme.typo.sizes.smallHero };
    }

    @media ( min-width: 750px ) {
      font-size: ${ p => p.theme.typo.sizes.hero };
    }
  }
`

const StyledIntroCopy = styled.div`
  ${ p => p.theme.typo.generalSans }
  font-size: ${ p => p.theme.typo.sizes.tinyHero };

  > * {
    &:first-child {
      margin-top: 0;
    }
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ( min-width: 750px ) {
    font-size: ${ p => p.theme.typo.sizes.smallHero };
  }
  
  @media ( min-width: 750px ) {
    font-size: ${ p => p.theme.typo.sizes.hero };
  }
`

interface StyledLink {
  $style: 'link' | 'button',
}

const StyledLink = styled.div<StyledLink>`
  ${ p => p.$style === 'link' && css`
    a {
      display: inline-block;
      margin-top: 2rem;
      ${ p.theme.typo.generalSansSemiBold }
      font-size: ${ p.theme.typo.sizes.small };
      color: inherit;
      ${ textLink }
    }
  ` }

  ${ p => p.$style === 'button' && css`
    a {
      justify-self: flex-end;
      display: inline-flex;
      align-items: center;
      margin-top: 4rem;
      padding-left: 1rem;
      ${ p.theme.typo.generalSansSemiBold }
      background: ${ p.theme.colors.black };
      color: ${ p.theme.colors.white };
      text-decoration: none;
      
      > span {
        margin-left: 1rem;
        padding: 0.8rem;
        background: ${ p.theme.colors.red };

        svg {
          height: 22px;

          > * {
            stroke: ${ p.theme.colors.black };
          }
        }
      }
    }
  ` }
`

export default Hero
