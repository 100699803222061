import React from 'react'
import { cn } from '../../lib/cn'

export const containerWidth = 87 // vw
export const containerPaddingWidth = ( 100 - containerWidth ) / 2
export const defaultMaxWidth = 65 // rem

export default function Container( { children, fullWidth = false }: 
  {children: React.ReactNode, fullWidth?: boolean} ) {
  return (
    <div className={ cn( 'mx-auto box-content', fullWidth ? 'max-w-[calc(65rem+13vw)]' : 'max-w-[65rem] w-[87vw] px-[6.5vw]' ) }>
      {children}
    </div>
  )
}

/**
 * Only works when Container has a maxWidth
 */
export const getMaxElementWidthInRem = ( { 
  maxWidth = defaultMaxWidth,
  cols = 1,
  gutter = 0,
} ) => ( maxWidth - ( gutter * ( cols - 1 ) ) ) / cols

// interface Container {
//   max?: number | null;
//   centered?: boolean,
//   fullWidth?: boolean,
// }

// const Container_OLD = styled.div<Container>`
//   position: relative;
//   display: block;
//   width: ${ p => p.fullWidth ? 100 : containerWidth }%;
//   margin: 0px auto;

//   ${ p => !p.fullWidth && css`
//     padding-left: ${ containerPaddingWidth }%;
//     padding-right: ${ containerPaddingWidth }%;
//   ` }

//   ${ p => p.max && css`
//     max-width: ${ p.max }rem;

//     ${ p.fullWidth && css`
//       max-width: calc( ${ p.max }rem + ( ${ containerPaddingWidth }vw * 2 ) );
//     ` }
//   ` }

//   ${ p => p.centered && css`
//     text-align: center;
//   ` }
// `

// Container.defaultProps = {
//   max: 65,
// }

// export default Container
