import { useState, useRef } from 'react'
import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import Link from './Link'
import useModalTraits from '../hooks/useModalTraits'
import { containerPaddingWidth } from './layout/Container'
import BurgerMenu from './icons/BurgerMenu'
import FULogo, { StyledLogo } from './FULogo'
import SocialLink from './SocialLink'
import SearchToggle from './search/SearchToggle'
import Search from './icons/Search'
import type { ColorKeys } from '../styles/colors'

export const breakpoint = 900

const navItems = [
  {
    href: '/',
    text: 'Home',
    mobileOnly: true,
  },
  {
    href: '/training',
    text: 'Training',
  },
  {
    href: '/helpdesk',
    text: 'Helpdesk',
  },
  {
    href: '/stories',
    text: 'Stories & Investigations',
  },
  {
    href: '/about',
    text: 'About',
  },
]

const findActiveItem = ( asPath, items ) => {
  if ( !items.length ) {
    return null
  }

  const getScore = ( path, href ) => {
    if ( path === href ) {
      return 2
    }

    if ( path.startsWith( href ) ) {
      return 1
    }

    return 0
  }

  const evaluatedItems = items.map( ( item, index ) => ( {
    index,
    item,
    score: getScore( asPath, item.href ),
  } ) )

  if ( evaluatedItems.find( i => i.score === 2 ) ) {
    return evaluatedItems.find( i => i.score === 2 ).index
  }

  if ( evaluatedItems.find( i => i.score === 1 ) ) {
    return evaluatedItems.find( i => i.score === 1 ).index
  }

  return null
}

function Nav( { 
  color = 'black' as ColorKeys, 
  condensedHeader, 
} ) {
  // const navToggleBtnRef = useRef( null )
  const navSliderRef = useRef( null )
  const [isOpen, setIsOpen] = useState( false )
  const router = useRouter()
  const activeItemIndex = findActiveItem( router.asPath, navItems )

  const toggleNav = () => setIsOpen( s => !s )

  useModalTraits( {
    isOpen,
    close: () => setIsOpen( false ),
    containerRef: navSliderRef,
    focusableElementsSelector: 'a, button',
    // includeInFocusTrap: [navToggleBtnRef],
  } )

  return (
    <StyledNav 
      ref={ navSliderRef }
      $isOpen={ isOpen }
      $color={ color }
      $condensedHeader={ condensedHeader }
    >
      <StyledNavButton $condensedHeader={ condensedHeader }>
        <button
          // ref={ navToggleBtnRef }
          type="button"
          aria-label="Menu"
          onClick={ toggleNav }
        >
          <BurgerMenu />
        </button>
      </StyledNavButton>
      <StyledNavList>
        <StyledLogo $breakpoint={ breakpoint }>
          <Link href="/">
            <FULogo textColor="white" />
          </Link>
        </StyledLogo>
        { navItems.map( ( navItem, i ) => (
          <StyledNavItem
            key={ navItem.href }
            $isActive={ activeItemIndex === i }
            $mobileOnly={ navItem.mobileOnly }
          >
            <Link
              href={ navItem.href }
              passHref
            >
              <span>
                { navItem.text }
              </span>
            </Link>
          </StyledNavItem>
        ) ) }
        <StyledSearchButton>
          <SearchToggle ariaLabel="Open the search bar">
            <Search />
          </SearchToggle>
        </StyledSearchButton>
        <StyledNavFooter>
          <SocialLink
            orgName="FinanceUncovered"
            href="https://twitter.com/finuncovered"
            twitter
          >
            Follow us
          </SocialLink>
        </StyledNavFooter>
      </StyledNavList>
    </StyledNav>
  )
}

interface WithIsOpen {
  $isOpen: boolean,
}

interface WithCondensedHeader {
  $condensedHeader: boolean,
}

const StyledNavButton = styled.div<WithCondensedHeader>`
  z-index: 1;
  position: fixed;
  right: ${ containerPaddingWidth }vw;
  top: ${ p => p.$condensedHeader ? '2rem' : '3.5rem' };
  transition: top .3s ease-out;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    transform: translate( -50%, -50% );
    border-radius: 100px;
    background: ${ p => p.theme.bg };
    transition: opacity .3s ease-out .3s;
  }

  button {
    position: relative;
    display: block;
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
  }

  svg {
    display: block;

    * {
      fill: ${ p => p.theme.fg };
      transition: fill .3s ease-out;
    }
  }
`

const StyledNavList = styled.div`
  font-size: ${ p => p.theme.typo.sizes.normal };
`

interface StyledNavItem {
  $isActive: boolean,
  $mobileOnly: boolean,
}

const StyledNavItem = styled.div<StyledNavItem>`
  a {
    position: relative;
    display: block;
    ${ p => p.theme.typo.generalSansSemiBold }
    font-size: ${ p => p.theme.typo.sizes.dropped };
    color: var( --fu-nav-fg );
    text-decoration: none;

    span {
      position: relative;
    }

    ${ p => p.$isActive && css`
      span {
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background: ${ p.theme.colors.red };
          top: calc( 100% + 3px);
          left: 0;
          pointer-events: none;
          transform-origin: 0% 50%;
          transform: scale3d(1, 1, 1);
          transition: transform 0.3s ease-out;
        }
      }
    ` }
    
    &:hover {
      color: ${ p => p.theme.colors.red };
    }
  }
  
  ${ p => p.$mobileOnly && css`
    @media ( min-width: ${ breakpoint + 1 }px ) {
      display: none;
    }
  ` }
`

const StyledSearchButton = styled.div`
  button {
    display: block;
    box-sizing: content-box;
    border: 0;
    border-radius: 100%;
    height: 19px;
    background: transparent;
    transition: 
      background-color .3s ease-out;

    &:hover,
    &:focus-visible {
      background-color: ${ p => rgba( p.theme.colors.blueGrey__deep, 0.2 ) };
    }
  }
`

const StyledNavFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.8rem ${ containerPaddingWidth }vw;
  display: flex;
  align-items: center;
  border-top: 1px solid ${ p => p.theme.colors.white };
  color: ${ p => p.theme.colors.white };

  ${ p => p.theme.typo.generalSansSemiBold }
  font-size: ${ p => p.theme.typo.sizes.dropped };

  a {
    color: inherit;
  }

  svg * {
    fill: ${ p => p.theme.colors.white };
  }
`

interface StyledNav extends WithIsOpen {
  $color: ColorKeys,
  $condensedHeader: boolean,
}

const StyledNav = styled.div<StyledNav>`
  z-index: 1;

  ${ StyledNavButton } {
    ${ p => p.$isOpen && css`
      svg * {
        fill: ${ p.theme.colors.white };
      }

      &:before {
        opacity: 0;
        transition: opacity .15s ease-out;
      }
    ` }
  }

  @media ( max-width: ${ breakpoint }px ) {
    --fu-nav-bg: ${ p => p.theme.colors.black };
    --fu-nav-fg: ${ p => p.theme.colors.white };

    ${ StyledNavList } {
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      background: ${ p => p.theme.colors.black };
      padding: 9rem 0 3rem;
      transform: translateX( 100% );
      transition: transform .3s ease-out;

      ${ p => p.$isOpen && css`
        transform: translateX( 0% );
      ` }

      ${ StyledLogo } {
        position: absolute;
        top: ${ p => p.$condensedHeader ? '1.5rem' : '3.5rem' };
        left: ${ containerPaddingWidth }vw;
      }

      ${ StyledNavFooter },
      ${ StyledLogo } {
        opacity: 0;
        transition: opacity .3s ease-out;

        ${ p => p.$isOpen && css`
          opacity: 1;
          transition-delay: .3s;
        ` }
      }
    }

    ${ StyledNavItem } a,
    ${ StyledSearchButton } {
      display: block;
      padding: 0.5rem ${ containerPaddingWidth }vw;
      opacity: 0;
      transition: opacity .3s ease-out;

      ${ p => p.$isOpen && css`
        opacity: 1;
        transition-delay: .3s;

        &:nth-child( 2 ) {
          transition-delay: .35s;
        }

        &:nth-child( 3 ) {
          transition-delay: .4s;
        }

        &:nth-child( 4 ) {
          transition-delay: .45s;
        }

        &:nth-child( 5 ) {
          transition-delay: .5s;
        }
        
        &:nth-child( 6 ) {
          transition-delay: .55s;
        }
        
        &:nth-child( 7 ) {
          transition-delay: .6s;
        }
      ` }

      span {
        display: inline-block;
      }
    }
  }

  @media ( min-width: ${ breakpoint + 1 }px ) {
    --fu-nav-bg: ${ p => p.$color === 'white' ? p.theme.colors.black : p.theme.colors.white };
    --fu-nav-fg: ${ p => p.theme.getColor( p.$color ) };

    ${ StyledNavButton } {
      display: none;
    }

    ${ StyledNavList } {
      display: flex;
      margin: 0 -0.9rem;

      a,
      button {
        padding: 0.9rem;
      }

      ${ StyledLogo },
      ${ StyledNavFooter } {
        display: none;
      }
    }
  }

  @media print {
    display: none;
  }
`

export default Nav
